import React, { Component, useState, useCallback } from 'react';
import { Field, Label, Input, Button, Transition } from '@headlessui/react';
import Spinner from '../../components/Spinner';
import { CountrySelect, StateSelect } from 'react-country-state-city';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReactInputMask from 'react-input-mask';
import { useDropzone } from "react-dropzone";
import { ReactComponent as IconFileImage } from '../../assets/icons/icon-image.svg';

const MAX_FILESIZE_NUMERIC = 5
const MAX_FILESIZE_BYTES = MAX_FILESIZE_NUMERIC * 1048576

function FileUpload({ setFile }) {
  const [fileName, setFileName] = useState()
  const [fileUrl, setFileUrl] = useState()
  const [errors, setErrors] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setErrors([])
    if(acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFileName(file.name)
      setFileUrl(URL.createObjectURL(file))
      setFile(file)
    }

    if(rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      setErrors(file.errors)
    }
  }, [setFile])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg']},
    maxSize: MAX_FILESIZE_BYTES,
    onDrop
  })

  return (
    <div
      {...getRootProps()}
      className="border bg-slate-50 rounded-md p-5 flex flex-col items-center justify-center text-center cursor-pointer min-h-[100px]"
    >
      <input {...getInputProps()} />
      <Transition
        show={!!fileName}
        enter="duration-500 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="mb-1">
          <img
            src={fileUrl}
            alt="Logo preview"
            className="max-h-24 object-contain mx-auto"
          />
          <p className="font-semibold mt-1">{fileName}</p>
        </div>
      </Transition>
      {errors.length > 0 && errors.map(err => <p key={err.code} className="text-red-500">{err.message}</p>)}
      <div className="text-slate-500">
        <IconFileImage className="size-6 inline" />
        <p>Drag and drop your logo here.</p>
        <p>Max size: {MAX_FILESIZE_NUMERIC} MB</p>
      </div>
    </div>
  )
}

class FirmSetup extends Component {
  state = {
    logo: null,
    selectedFile: null,
    seats: '',
    new_company_data: null,
    new_user_data: null,
  }

  handleSubmit = () => {
    var { new_company_data, new_user_data } = this.state;
    if(new_company_data){
      if(new_company_data?.address_country_data){
        new_company_data.address_country = new_company_data.address_country_data?.name;
      }
      if(new_company_data?.address_state_data){
        new_company_data.address_state = new_company_data.address_state_data?.name;
      }
      if(new_company_data?.logo){
        var postData = new FormData();
        Object.keys(new_company_data).forEach(key => {
          postData.append(key, new_company_data[key]);
        })
        new_company_data = postData;
      }
      this.props.userUpdateCompany(
        new_company_data,
        {
          success: [
            {
              function: this.props.onComplete,
              params: []
            }
          ]
        }
      )
    }
    if(new_user_data){
      this.props.userUpdateData(
        new_user_data,
        {
          success: [
            {
              function: this.props.onComplete,
              params: []
            }
          ]
        }
    );
    }
  }

  setCompanyData = (key, value) => {
    const { new_company_data } = this.state;
    if(new_company_data){
      var new_company = {...new_company_data};
      new_company[key] = value;
      this.setState({new_company_data: new_company})
    } else {
      this.setState({new_company_data: {[key]: value}})
    }
  }

  setUserData = (key, value) => {
    const { new_user_data } = this.state;
    if(new_user_data){
      var new_user = {...new_user_data};
      new_user[key] = value;
      this.setState({new_user_data: new_user})
    } else {
      this.setState({new_user_data: {[key]: value}})
    }
  }

  render() {
    const { user_update_company_working, userData } = this.props;
    const { company } = userData;
    const { new_company_data, new_user_data } = this.state;

    return (
      <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75">
        <h2 className="font-semibold text-2xl mb-6">Let's set up your firm</h2>

        <div className="space-y-6">
          <Field className="field flex-1">
            <Label>Firm Name</Label>
            <Input
              type="text"
              required
              value={new_company_data?.name
                  ? new_company_data?.name
                  : company?.name || ""
              }
              onChange={(e) => this.setCompanyData("name", e.target.value)}
            />
          </Field>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Firm Tax ID Number</Label>
              <ReactInputMask
                mask="99-9999999"
                alwaysShowMask
                value={new_company_data?.tin
                    ? new_company_data?.tin
                    : company?.tin || ""
                }
                onChange={(e) => this.setCompanyData("tin", e.target.value)}
              />
            </Field>
            <Field className="field">
              <Label>IRS Transmitter Control Code (TCC)</Label>
              <Input
                type="text"
                maxLength={5}
                pattern="[A-Z0-9]{5}"
                className="uppercase"
                placeholder="XXXXX"
                value={new_company_data?.tin_tcc
                    ? new_company_data?.tin_tcc
                    : company?.tin_tcc || ""
                }
                onChange={(e) => this.setCompanyData("tin_tcc", e.target.value.toUpperCase())}
              />
              <small className="text-gray-500">5-character code provided by the IRS</small>
            </Field>
          </div>

          <hr />

          <h2 className="font-semibold mb-6">Firm Address</h2>
          <Field className="field">
            <Label>Address Line 1*</Label>
            <Input
              type="text"
              required
              value={new_company_data?.address_line_1
                  ? new_company_data?.address_line_1
                  : company?.address_line_1 || ""
              }
              onChange={(e) => this.setCompanyData("address_line_1", e.target.value)}
            />
          </Field>
          <Field className="field">
            <Label>Address Line 2</Label>
            <Input
              type="text"
              value={new_company_data?.address_line_2
                  ? new_company_data?.address_line_2
                  : company?.address_line_2 || ""
              }
              onChange={(e) => this.setCompanyData("address_line_2", e.target.value)}
            />
          </Field>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Country*</Label>
              <CountrySelect
                placeHolder="Select country"
                showFlag={false}
                defaultValue={new_company_data?.address_country_data
                  ? new_company_data?.address_country_data
                  : company?.address_country_data || null
                }
                onChange={e => this.setCompanyData("address_country_data", e)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>State/Province</Label>
              <StateSelect
                placeHolder="Select state/province"
                countryid={new_company_data?.address_country_data?.id
                  ? new_company_data?.address_country_data?.id
                  : company?.address_country_data?.id || null
                }
                defaultValue={new_company_data?.address_state_data
                  ? new_company_data?.address_state_data
                  : company?.address_state_data || null
                }
                //disabled={!this.state.address_country_data}
                onChange={e => this.setCompanyData("address_state_data", e)}
              />
            </Field>
          </div>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>City</Label>
              <Input
                type="text"
                value={new_company_data?.address_city
                    ? new_company_data?.address_city
                    : company?.address_city || ""
                }
                onChange={(e) => this.setCompanyData("address_city", e.target.value)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Zip Code*</Label>
              <Input
                type="text"
                className="max-w-36"
                value={new_company_data?.address_zip
                    ? new_company_data?.address_zip
                    : company?.address_zip || ""
                }
                onChange={(e) => this.setCompanyData("address_zip", e.target.value)}
              />
            </Field>
          </div>

          <hr />

          <h2 className="font-semibold mb-6">Business Manager</h2>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>First Name</Label>
              <Input
                type="text"
                defaultValue={userData?.first_name || ""}
                onChange={(e) => this.setUserData("first_name", e.target.value)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Last Name</Label>
              <Input
                type="text"
                defaultValue={userData?.last_name || ""}
                onChange={(e) => this.setUserData("last_name", e.target.value)}
              />
            </Field>
          </div>

          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Email</Label>
              <Input
                type="email"
                defaultValue={userData?.email || ""}
                onChange={(e) => this.setUserData("email", e.target.value)}
                disabled={true}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Phone</Label>
              <PhoneInput
                international
                //defaultValue={userData?.phone || ""}
                defaultCountry={new_company_data?.address_country_data?.iso2
                  ? new_company_data?.address_country_data?.iso2
                  : company?.address_country_data?.iso2 || null
                }
                value={new_user_data?.phone
                  ? new_user_data?.phone
                  : userData?.phone || ""
                }
                onChange={value => this.setUserData("phone", value)}
              />
            </Field>
          </div>

          <hr />

          <Field className="field">
            <Label>Firm Logo</Label>
            <FileUpload
              setFile={file => this.setCompanyData("logo", file)}
            />
          </Field>

          <Field className="field">
            <Label>Expected Number of Seats</Label>
            <Input
              type="number"
              min="1"
              defaultValue="1"
              className="w-48"
              onChange={(e) => this.setState({ seats: e.target.value })}
            />
          </Field>

          <Button
            className="btn-primary w-full"
            disabled={user_update_company_working}
            onClick={this.handleSubmit}
          >
            <span className="flex gap-1 justify-center items-center">
            {user_update_company_working && <Spinner />}
            <span>Continue &rarr;</span>
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default FirmSetup;
