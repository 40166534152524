import React, { Component } from 'react';
import { connect } from "react-redux";
import FirmSetup from './FirmSetup';
import ReportsInfo from './ReportsInfo';
import OnboardingComplete from './OnboardingComplete';
import userActions from "../../redux/user/actions";

const {
  userUpdateCompany,
  userUpdateData,
} = userActions;

class Onboarding extends Component {
  state = {
    step: 'firm_setup'
  }

  handleFirmSetupComplete = () => {
    this.setState({ step: 'reports' });
  }

  render() {
    const { userData } = this.props;
    const { step } = this.state;

    return (
      <div className="max-w-4xl mx-auto">
        {step === 'firm_setup' && (
          <FirmSetup
            onComplete={this.handleFirmSetupComplete}
            userData={userData}
            userUpdateCompany={this.props.userUpdateCompany}
            user_update_company_working={this.props.user_update_company_working}
            userUpdateData={this.props.userUpdateData}
          />
        )}
        {step === 'reports' && (
          <ReportsInfo onComplete={() => this.setState({ step: 'complete' })} />
        )}
        {step === 'complete' && (
          <OnboardingComplete />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_update_company_working: state.User.get("user_update_company_working"),
  }),
  {
    userUpdateCompany,
    userUpdateData,
  }
)(Onboarding);
