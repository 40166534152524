import React, { Component, useState, useCallback } from 'react';
import { Field, Label, Input, Button, Transition } from '@headlessui/react';
import Spinner from '../../../components/Spinner';
import { CountrySelect, StateSelect } from 'react-country-state-city';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReactInputMask from 'react-input-mask';
import { useDropzone } from "react-dropzone";
import { ReactComponent as IconFileImage } from '../../../assets/icons/icon-image.svg';

const MAX_FILESIZE_NUMERIC = 5
const MAX_FILESIZE_BYTES = MAX_FILESIZE_NUMERIC * 1048576

function FileUpload({ setFile, existingLogo }) {
  const [fileName, setFileName] = useState()
  const [fileUrl, setFileUrl] = useState(existingLogo)
  const [errors, setErrors] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setErrors([])
    if(acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFileName(file.name)
      setFileUrl(URL.createObjectURL(file))
      setFile(file)
    }

    if(rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      setErrors(file.errors)
    }
  }, [setFile])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg']},
    maxSize: MAX_FILESIZE_BYTES,
    onDrop
  })

  const handleRemove = (e) => {
    e.stopPropagation()
    setFileName(null)
    setFileUrl(null)
    setFile(null)
  }

  return (
    <div
      {...getRootProps()}
      className="border bg-slate-50 rounded-md p-5 flex flex-col items-center justify-center text-center cursor-pointer h-full"
    >
      <input {...getInputProps()} />
      <Transition
        show={!!fileUrl}
        enter="duration-500 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="mb-1 relative">
          <span
            className="size-6 leading-none bg-red-500 text-white absolute -top-2 -right-2 bg-white rounded-full p-1 shadow"
            role="button"
            onClick={handleRemove}
          >
            &times;
          </span>
          <img
            src={fileUrl}
            alt="Logo preview"
            className="max-h-24 object-contain mx-auto"
          />
          <p className="font-semibold mt-1">{fileName}</p>
        </div>
      </Transition>
      {errors.length > 0 && errors.map(err => <p key={err.code} className="text-red-500">{err.message}</p>)}
      <div className="text-slate-500">
        <IconFileImage className="size-6 inline" />
        <p>To change your logo, drag and drop your file here.</p>
        <p>Max size: {MAX_FILESIZE_NUMERIC} MB</p>
      </div>
    </div>
  )
}

const company = {
  name: "Smith & Associates, LLC",
  tin: "12-3456789", 
  tin_tcc: "AB123",
  logo: "https://placehold.co/400x400/e2e8f0/64748b?text=S%26A",
  address_line_1: "123 Main St",
  address_line_2: "Apt 4B",
  address_city: "San Francisco",
  address_zip: "94101",
  address_state_data: {
    "id": 1416,
    "name": "California",
    "state_code": "CA"
  },
  address_country_data: {
    "name": "United States",
    "iso2": "US",
  },
  business_manager: {
    first_name: "John",
    last_name: "Smith",
    email: "john.smith@smithassociates.com",
    phone: "+14155552671"
  }
}


class BMFirmSetup extends Component {
  state = {
    selectedFile: null,

  }

  handleSubmit = () => {
    console.log('submit')
  }

  render() {
    const { update_company_working } = this.props;

    return (
      <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75">
        <h2 className="font-semibold text-2xl mb-6">Firm Setup</h2>

        <div className="space-y-6">
          <Field className="field flex-1">
            <Label>Firm Name</Label>
            <Input
              type="text"
              required
              value={company?.name || ""}
              onChange={(e) => this.props.updateCompany("name", e.target.value)}
            />
          </Field>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Firm Tax ID Number</Label>
              <ReactInputMask
                mask="99-9999999"
                alwaysShowMask
                value={company?.tin || ""}
                onChange={(e) => this.props.updateCompany("tin", e.target.value)}
              />
            </Field>
            <Field className="field">
              <Label>IRS Transmitter Control Code (TCC)</Label>
              <Input
                type="text"
                maxLength={5}
                pattern="[A-Z0-9]{5}"
                className="uppercase"
                placeholder="XXXXX"
                value={company?.tin_tcc || ""}
                onChange={(e) => this.props.updateCompany("tin_tcc", e.target.value.toUpperCase())}
              />
              <small className="text-gray-500">5-character code provided by the IRS</small>
            </Field>
          </div>

          <hr />

          <h2 className="font-semibold mb-6">Firm Address</h2>
          <Field className="field">
            <Label>Address Line 1*</Label>
            <Input
              type="text"
              required
              value={company?.address_line_1 || ""}
              onChange={(e) => this.props.updateCompany("address_line_1", e.target.value)}
            />
          </Field>
          <Field className="field">
            <Label>Address Line 2</Label>
            <Input
              type="text"
              value={company?.address_line_2 || ""}
              onChange={(e) => this.props.updateCompany("address_line_2", e.target.value)}
            />
          </Field>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Country*</Label>
              <CountrySelect
                placeHolder="Select country"
                showFlag={false}
                defaultValue={company?.address_country_data || null}
                // onChange={e => this.props.updateCompany("address_country_data", e)}
                onChange={e => console.log(e)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>State/Province</Label>
              <StateSelect
                placeHolder="Select state/province"
                countryid={company?.address_country_data?.id || null}
                defaultValue={company?.address_state_data || null}
                // onChange={e => this.props.updateCompany("address_state_data", e)}
                onChange={e => console.log(e)}
              />
            </Field>
          </div>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>City</Label>
              <Input
                type="text"
                value={company?.address_city || ""}
                onChange={(e) => this.props.updateCompany("address_city", e.target.value)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Zip Code*</Label>
              <Input
                type="text"
                className="max-w-36"
                value={company?.address_zip || ""}
                onChange={(e) => this.props.updateCompany("address_zip", e.target.value)}
              />
            </Field>
          </div>

          <hr />

          <h2 className="font-semibold mb-6">Business Manager</h2>
          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>First Name</Label>
              <Input
                type="text"
                value={company?.business_manager?.first_name || ""}
                onChange={(e) => this.props.updateUser("first_name", e.target.value)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Last Name</Label>
              <Input
                type="text"
                value={company?.business_manager?.last_name || ""}
                onChange={(e) => this.props.updateUser("last_name", e.target.value)}
              />
            </Field>
          </div>

          <div className="flex gap-4">
            <Field className="field flex-1">
              <Label>Email</Label>
              <Input
                type="email"
                value={company?.business_manager?.email || ""}
                onChange={(e) => this.props.updateUser("email", e.target.value)}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Phone</Label>
              <PhoneInput
                international
                defaultCountry={company?.address_country_data?.iso2 || null}
                value={company?.business_manager?.phone || ""}
                onChange={value => this.props.updateUser("phone", value)}
              />
            </Field>
          </div>

          <hr />

          <Field className="field">
            <Label>Firm Logo</Label>
            <FileUpload
              setFile={file => this.setState({ selectedFile: file })}
              existingLogo={company?.logo}
            />
          </Field>

          <Button
            className="btn-primary w-full"
            disabled={update_company_working}
            onClick={this.handleSubmit}
          >
            <span className="flex gap-1 justify-center items-center">
              {update_company_working && <Spinner />}
              <span>Save Changes</span>
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default BMFirmSetup;