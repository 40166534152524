import { Map } from "immutable";

export function saveTokenLocal(data){
	//save token local
	if(data.access){
		localStorage.setItem("access_token", data.access);
	}
	if(data.refresh){
		localStorage.setItem("refresh_token", data.refresh);
	}
}

export function clearTokenLocal() {
	//clear token local
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");
}

export function getTokenLocal() {
	try {
		const access_token = localStorage.getItem("access_token");
		const refresh_token = localStorage.getItem("refresh_token");
		return new Map({ access_token, refresh_token });
	} catch (err) {
		clearTokenLocal();
		return new Map();
	}
}

export function isOnboarded(data) {
  const keys = ["name", "tin", "tin_tcc", "address_line_1", "address_line_2",
                "address_country", "address_state", "address_city",
                "address_zip"]
  var onboarded = true;
  keys.forEach(key => {
    if(!data[key]){ onboarded = false };
  })
  return onboarded;
}
