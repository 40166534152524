import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ToastContainer } from 'react-toastify';
import { ReactComponent as Logo } from '../assets/images/event-ledger-logo-sm.svg'
import { Menu, MenuButton, MenuItem, MenuItems, MenuSeparator } from "@headlessui/react"
import { NavLink } from "react-router-dom"
import Caret from "./Caret"
import authActions from "../redux/auth/actions";
import userActions from "../redux/user/actions";
import 'react-toastify/dist/ReactToastify.css';

const { userLogout } = authActions;
const {
  userGetData,
} = userActions;

class TopBar extends Component {

  state = {
    user_data_loaded: false,
  }

  componentDidMount = () => {
    if(!this.props.userData?.id){
      this.props.userGetData();
    }
  }

  componentDidUpdate = (pp) => {
    if(!pp.userData?.id && !this.state.user_data_loaded){
      this.props.userGetData();
      this.setState({ user_data_loaded: true });
    }
  }

  render() {
    const { userData } = this.props;
    console.log("userData:", userData)

    return (
      <div className="border-b border-b-2 border-slate-100 bg-slate-900 text-white">
        <div className="2xl:container 2xl:mx-auto flex justify-between px-4 py-3">
          <div>
            <Logo />
          </div>
          <div className="flex items-center justify-between flex-1 ml-20 pl-5">
            {userData?.active_client?.name &&
            <div>
              <span className="inline-block py-1 px-3 bg-orange-500/20 text-orange-400 rounded-full text-sm">
                <span>Working on: </span><span className="font-semibold">{userData?.active_client?.name}</span>
              </span>
              <NavLink to="/app/select-client" className="ml-3 text-sm no-underline text-blue-500">Switch clients</NavLink>
            </div>
            }
            <Menu>
              <MenuButton className="ml-auto">
                <span>{userData?.username}</span>
                <Caret className="inline ml-2" />
              </MenuButton>
              <MenuItems anchor="bottom end">
                <div className="p-3">
                  {userData?.company?.logo &&
                  <div className="mb-1">
                    <img
                      src={userData?.company?.logo}
                      alt="Company Logo"
                      className="h-4"
                    />
                  </div>
                  }
                  <small className="block font-semibold">{userData?.full_name}</small>
                  <small className="text-slate-500">{
                    userData?.user_type === "super_user"
                      ? "Business Manager"
                      : "Employee"
                  }</small>
                  <small className="block text-slate-500">{userData?.company?.name} ({String(userData?.company?.id?.toString() || "").padStart(8, "0")})</small>
                </div>
                <MenuSeparator className="h-px bg-slate-200" />
                <MenuSeparator className="h-px bg-slate-200" />
                <MenuItem>
                  <NavLink to="/app/settings">Settings</NavLink>
                </MenuItem>
                <MenuSeparator className="h-px bg-slate-200" />
                <MenuItem>
                  <span
                    role="button"
                    onClick={() => this.props.userLogout()}
                    className="text-red-500"
                  >Logout</span>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
        />
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userLogout,
      userGetData,
    }
  )(TopBar)
);
