import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Field, Input, Label, Switch, Textarea } from "@headlessui/react";
import { CountrySelect, StateSelect } from 'react-country-state-city'
import Spinner from "../../components/Spinner"
import PayorAccounts from "./PayorAccounts"
import payorsActions from "../../redux/payors/actions";

const {
  payorsAddPayor,
  payorsUpdatePayor,
  payorsAutofillSearch,
} = payorsActions;

class PayorForm extends Component {
  state = {
    new_payor_data: null,
    accounts: [],
  }

  setPayorData = (key, value) => {
    const { new_payor_data } = this.state;
    if(new_payor_data){
      var new_payor = {...new_payor_data};
      new_payor[key] = value;
      if(key === "address_country_data"){
        new_payor["address_country"] = value.name;
      }
      if(key === "address_state_data"){
        new_payor["address_state"] = value.name;
      }
      this.setState({new_payor_data: new_payor})
    } else {
      this.setState({new_payor_data: {[key]: value}})
      if(key === "address_country_data"){
        this.setState({address_country: value.name});
      }
      if(key === "address_state_data"){
        this.setState({address_state: value.name});
      }
    }
  }

  addPayor = () => {
    var data = {...this.state.new_payor_data};
    data["client_id"] = this.props.userData?.active_client?.id;
    this.props.payorsAddPayor(data)
  }

  updatePayor = () => {
    var data = {...this.state.new_payor_data};
    data["client_id"] = this.props.userData?.active_client?.id;
    data["id"] = this.props.data?.id;
    this.props.payorsUpdatePayor(data)
  }

  render() {
    const { payors_working } = this.props;
    const { new_payor_data } = this.state;

    return (
      <div>
        <div className="bg-slate-100/75 rounded-lg text-slate-600 p-4 mb-5">
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-semibold">General</h2>
            <div className="flex items-center gap-4">
              <Field className="flex items-center gap-2">
                <Switch
                  className="switch-sm"
                  checked={
                    new_payor_data?.is_global === undefined
                      ? this.props.data?.is_global
                        ? true
                        : false
                      : new_payor_data?.is_global
                        ? true
                        : false
                  }
                  onChange={() => this.setPayorData("is_global", this.props.data ? !this.props.data?.is_global : !new_payor_data?.is_global)}
                />
                <Label className="text-slate-800">Global Payor</Label>
              </Field>
              <Field className="flex items-center gap-2">
                <Switch
                  className="switch-sm"
                  checked={
                    new_payor_data?.is_active === undefined
                      ? this.props.data?.is_active
                        ? true
                        : false
                      : new_payor_data?.is_active
                        ? true
                        : false
                  }
                  onChange={() => this.setPayorData("is_active", this.props.data ? !this.props.data?.is_active : !new_payor_data?.is_active)}
                />
                <Label className="text-slate-800">{
                    new_payor_data?.is_active === undefined
                      ? this.props.data?.is_active
                        ? 'Active'
                        : 'Inactive'
                      : new_payor_data?.is_active
                        ? 'Active'
                        : 'Inactive'
                }</Label>
              </Field>
            </div>
          </div>
          <Field className="field">
            <Label>Payor Name</Label>
            <Input
              type="text"
              required
              onChange={(e) => this.setPayorData("name", e.target.value)}
              defaultValue={this.props?.data?.name}
              value={new_payor_data?.name}
            />
          </Field>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Field className="field">
              <Label>Address Line 1</Label>
              <Input
                type="text"
                required
                onChange={(e) => this.setPayorData("address_line_1", e.target.value)}
                defaultValue={this.props?.data?.address_line_1}
                value={new_payor_data?.address_line_1}
              />
            </Field>
            <Field className="field">
              <Label>Address Line 2</Label>
              <Input
                type="text"
                onChange={(e) => this.setPayorData("address_line_2", e.target.value)}
                defaultValue={this.props?.data?.address_line_2}
                value={new_payor_data?.address_line_2}
              />
            </Field>
          </div>
          <div className="flex gap-4 mt-4">
            <Field className="field flex-1">
              <Label>Country</Label>
              <CountrySelect
                placeHolder="Select country"
                showFlag={false}
                onChange={e => this.setPayorData("address_country_data", e)}
                defaultValue={new_payor_data?.address_country_data
                  ? new_payor_data?.address_country_data
                  : this.props.data?.address_country_data
                    ? this.props.data?.address_country_data
                    : null
                }
              />
            </Field>
            <Field className="field flex-1">
              <Label>State/Province</Label>
              <StateSelect
                placeHolder="Select state/province"
                countryid={this.state.new_payor_data?.address_country_data?.id}
                onChange={e => this.setPayorData("address_state_data", e)}
                defaultValue={new_payor_data?.address_state_data
                  ? new_payor_data?.address_state_data
                  : this.props.data?.address_state_data
                    ? this.props.data?.address_state_data
                    : null
                }
                disabled={!this.state.new_payor_data?.address_country_data && this.props.mode === 'add-payor'}
              />
            </Field>
            <Field className="field flex-1">
              <Label>City</Label>
              <Input
                type="text"
                onChange={(e) => this.setPayorData("address_city", e.target.value)}
                defaultValue={this.props?.data?.address_city}
                value={new_payor_data?.address_city}
              />
            </Field>
            <Field className="field flex-1">
              <Label>Zip Code</Label>
              <Input
                type="text"
                className="max-w-36"
                onChange={(e) => this.setPayorData("address_zip", e.target.value)}
                defaultValue={this.props?.data?.address_zip}
                value={new_payor_data?.address_zip}
              />
            </Field>
          </div>
          <Field className="field mt-4">
            <Label>Notes</Label>
            <Textarea
              onChange={(e) => this.setPayorData("notes", e.target.value)}
              defaultValue={this.props?.data?.notes}
              value={new_payor_data?.notes}
            />
          </Field>
        </div>
        <div className="bg-slate-100/75 rounded-lg text-slate-600 p-4 mb-5">
          <h2 className="font-semibold mb-4">Accounting</h2>
          <PayorAccounts
            data={[]}
            // addRow={}
            // deleteRow={}
          />
        </div>
        {this.props.data
          ?
            <Button
              className="btn-primary"
              disabled={payors_working && !this.state.new_payor_data}
              onClick={() => this.updatePayor()}
            >
              <span className="flex gap-1 justify-center items-center">
                {payors_working && <Spinner />}
                <span>Update Payor</span>
              </span>
            </Button>
          :
            <Button
              className="btn-primary"
              disabled={payors_working && !this.state.new_payor_data}
              onClick={() => this.addPayor()}
            >
              <span className="flex gap-1 justify-center items-center">
                {payors_working && <Spinner />}
                <span>Add Payor</span>
              </span>
            </Button>
        }
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      payors_working: state.Payors.get("payors_working"),
      payors_autofill: state.Payors.get("payors_autofill"),
      payors_autofill_working: state.Payors.get("payors_autofill_working"),
    }),
    {
      payorsAddPayor,
      payorsUpdatePayor,
      payorsAutofillSearch,
    }
  )(PayorForm)
);
