import React, { Component } from 'react';
import { Field, Label, Button, Textarea } from '@headlessui/react';
import Spinner from '../../components/Spinner';

class ReportsInfo extends Component {
  state = {
    is_submitting: false
  }

  handleSubmit = () => {
    this.setState({ is_submitting: true });

    const { onComplete } = this.props;
    onComplete();
  }

  render() {
    const { is_submitting } = this.state;

    return (
      <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75">
        <h2 className="font-semibold text-2xl mb-6">Report Import Instructions</h2>
        
        <div className="space-y-6">
          <p className="text-gray-600">
            We can help importing your reports at your current application (QuickBooks, Xero). Please inform us how to download your reports and what reports to download.
          </p>
          
          <Field className="field flex-1">
            <Label>Please be as detailed as possible</Label>
            <Textarea
              rows={8}
              onChange={(e) => this.setState({ reports_info: e.target.value })}
            />
          </Field>

          <Button
            className="btn-primary w-full"
            disabled={is_submitting}
            onClick={this.handleSubmit}
          >
            <span className="flex gap-1 justify-center items-center">
              {is_submitting && <Spinner />}
              <span>Finish &rarr;</span>
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default ReportsInfo;
