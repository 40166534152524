import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
	userData: {
		username: null,
	},
  updating_user_settings: false,
  reset_password_full_working: false,
  reset_password_full_executed: false,
  reset_password_full_validate_working: false,
  reset_password_full_validate_executed: false,
  user_search_data: {},
  user_search_data_loading: false,
  user_search_data_single: {},
  user_search_data_single_loading: false,
  slack_auth_loading: false,
  user_settings_loading: false,
  slack_channels: [],
  slack_channels_loading: false,
  slack_users: [],
  slack_users_loading: false,
  website_segmentation_loading: false,
  website_loading: false,
  employees: [],
  employees_loading: false,
  employee: {},
  announcement: null,
  intercom_auth_loading: false,
  user_switch_client_working: false,
  user_update_company_working: false,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {

    case "USER_UPDATE_COMPANY":
      return state
        .set("user_update_company_working", true)
    case "USER_UPDATE_COMPANY_SUCCESS":
      var user_update_company_success = state.get("userData");
      user_update_company_success.company = {...action.data.data}
      return state
        .set("userData", {...user_update_company_success})
        .set("user_update_company_working", false)
    case "USER_UPDATE_COMPANY_FAILED":
      return state
        .set("user_update_company_working", false)

    case "USER_CLEAR_DATA":
			return initState;

    case "USER_SWITCH_CLIENT":
      return state
        .set("user_switch_client_working", true)
    case "USER_SWITCH_CLIENT_SUCCESS":
      var user_switch_client_success = state.get("userData");
      user_switch_client_success.active_client = {...action.data.data}
      return state
        .set("userData", {...user_switch_client_success})
        .set("user_switch_client_working", false)
    case "USER_SWITCH_CLIENT_FAILED":
      return state
        .set("user_switch_client_working", false)

    case "USER_UPDATE_SETTINGS":
      var user_settings = state.get("userData");
      user_settings.settings = {
        ...user_settings.settings,
        ...action.data
      }
      return state
        .set("userData", {...user_settings})
        .set("user_settings_loading", true)
    case "USER_UPDATE_SETTINGS_SUCCESS":
      var user_settings_success = state.get("userData");
      user_settings_success.settings = {
        ...user_settings_success.settings,
        ...action.data.data
      }
      return state
        .set("userData", {...user_settings_success})
        .set("user_settings_loading", false)
    case "USER_UPDATE_SETTINGS_FAILED":
      return state
        .set("user_settings_loading", false)

		case "USER_PASSWORD_RESET":
			return state
				.set("user_data_saving", true)
		case "USER_PASSWORD_RESET_SUCCESS":
			return state
				.set("user_data_saving", false)
		case "USER_PASSWORD_RESET_FAILED":
			return state
				.set("user_data_saving", false)

		case "USER_UPDATE_DATA":
			return state
				.set("user_data_saving", true)
    case "USER_UPDATE_DATA_SUCCESS":
			return state
				.set("userData", {...state.get("userData"), ...action.data.data})
				.set("user_data_saving", false)
		case "USER_UPDATE_DATA_ERROR":
			return state
				.set("user_data_saving", false)

		case actions.USER_GET_DATA_SUCCESS:
			return state
				.set("userData", Object.assign({}, action.data.data))
				.set("data_up_to_date", true)

		case actions.USER_GET_DATA_ERROR:
			return state
				.set("data_up_to_date", false)

		default:
			return state;
  }
}
