import React from 'react'
import { NavLink } from "react-router-dom"
import { ReactComponent as IconHome } from '../assets/icons/icon-home.svg'
import { ReactComponent as IconReload } from '../assets/icons/icon-reload.svg'
import { ReactComponent as IconReporting } from '../assets/icons/icon-reporting.svg'
import { ReactComponent as IconVendors } from '../assets/icons/icon-vendors.svg'
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import Caret from "./Caret"
import {
  useLocation,
  //useParams
} from "react-router"
import { ReactComponent as IconDashboard } from '../assets/icons/icon-dashboard.svg'
import { ReactComponent as IconBuilding } from '../assets/icons/icon-building.svg'
import { ReactComponent as IconCalculator } from '../assets/icons/icon-calculator.svg'
import { ReactComponent as IconClient } from '../assets/icons/icon-client.svg'

export default function MainNav({ userData }) {
  const currenLoc = useLocation()
  //const params = useParams()
  const client_id = userData?.active_client?.slug
  const menu = [
    {title: 'Home', url: `/app/client/${client_id}/home`, icon: <IconHome className="size-4"/>, childs: []},
    {title: 'Transactions', icon: <IconReload className="size-4"/>, 
    urls: [`/app/client/${client_id}/invoices`, `/app/client/${client_id}/credit-cards`, `/app/client/${client_id}/deposits`],
    childs: [
      {title: 'All Invoices', url: `/app/client/${client_id}/invoices/all`, param: '/open', activeRoutes: ['/open', '/reviewed', '/approved', '/paid', '/hold', '/rejected', '/view']},
      {title: 'Enter Invoice', url: `/app/client/${client_id}/invoices/enter`, param: '', activeRoutes: ['/invoices/enter']},
      {title: 'Upload Invoice', url: `/app/client/${client_id}/invoices/upload`, param: '', activeRoutes: ['/upload']},
      {title: 'Recurring', url: `/app/client/${client_id}/invoices/templates`, param: '', activeRoutes: ['/templates', '/edit-template']},
      {title: 'Credit Cards', url: `/app/client/${client_id}/credit-cards/statements`, param: '', activeRoutes: ['/credit-cards']},
      {title: 'Deposits', url: `/app/client/${client_id}/deposits`, param: '', activeRoutes: ['/deposits']},
    ]},
    {title: 'Reporting', url: `/app/client/${client_id}/reporting`, icon: <IconReporting className="size-4"/>, childs: []},
    {title: 'Vendors', url: `/app/client/${client_id}/vendors`, icon: <IconVendors className="size-4"/>, childs: []},
    {title: 'Accounting', urls: [`/app/client/${client_id}/accounting`], icon: <IconCalculator className="size-4"/>, childs: [
      {title: 'GL Accounts', url: `/app/client/${client_id}/accounting/gl-accounts`, param: '', activeRoutes: ['/gl-accounts']}
    ]},
  ]

  const bm_menu = [
    {title: 'BM Dashboard', url: `/app/bm/dashboard`, icon: <IconDashboard className="size-4" />, childs: []},
    {title: 'Clients & Groups', url: `/app/bm/client-group-settings`, icon: <IconClient className="size-4"/>, childs: []},
    {title: 'Firm Setup', url: `/app/bm/firm-setup`, icon: <IconBuilding className="size-4"/>, childs: []},
  ]

  function checkActiveRoute(activeRoutes, pathname) {
    let result = false
    activeRoutes.forEach( routeItem => {
      if(result) return
      result = pathname.includes(routeItem)
    })
    return result
  }

  return (
    // initial entry for BM should be at BM Dashboard, so no client should be pre-selected (no active client)
    // at BM dashboard, BM can select client link and that's where BM act like employee, viewing particular client's home
    // for employees we can pre-select the last client they've been working on
    userData.user_type === 'super_user' && currenLoc.pathname.includes('/app/bm') ? // for temporary, later check if active client id/slug is defined or not
    <>
    <span className="block mb-2 text-slate-400 text-xs uppercase">Business Manager</span>
    <div className="flex flex-col gap-5">
      {bm_menu.map(item => (
        <NavLink key={item.title} to={item.url} className="flex gap-2 items-center no-underline font-normal text-slate-500 hover:text-blue-700 leading-none">
          {item.icon}
          <span>{item.title}</span>
        </NavLink>
      ))}
      {(userData.user_type === "super_user" && userData?.active_client) &&
        <div className="flex-grow">
          <NavLink
            to={menu[0].url}
            className={`fixed bottom-6 flex gap-1 items-center no-underline font-normal text-slate-500 hover:text-blue-700 
                      leading-none border rounded p-2 bg-slate-100`}
          >
            &larr; <span className="truncate max-w-28">{userData?.active_client?.name}</span>
          </NavLink>
        </div>
        }
    </div>
    </>
    :
    <div className="flex flex-col gap-5">
      {menu.map(item => (
        item.childs.length === 0 ?
        <NavLink key={item.title} to={item.url} className="flex gap-2 items-center no-underline font-normal text-slate-500 hover:text-blue-700 leading-none">
          {item.icon}
          <span>{item.title}</span>
        </NavLink>
        :
        <Disclosure key={item.title} defaultOpen={checkActiveRoute(item.urls, currenLoc.pathname)}>
          <DisclosureButton className="group p-0 shadow-none flex gap-2 items-center font-normal text-slate-500 hover:text-blue-700 leading-none">
            {item.icon}
            <span className="flex-grow text-left">{item.title}</span>
            <Caret className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="flex flex-col gap-2 pl-6 -mt-2">
            {item.childs.map(child => (
              <NavLink
                key={child.title}
                to={child.url + child.param}
                className={`
                  no-underline font-normal text-[14px] text-slate-500 hover:text-blue-700
                  ${checkActiveRoute(child.activeRoutes, currenLoc.pathname) ? 'active' : ''}
                `}
                data-check={child.activeRoutes.indexOf(currenLoc.pathname)}
              >
                {child.title}
              </NavLink>
            ))}
          </DisclosurePanel>
        </Disclosure>
        ))}
        {userData.user_type === "super_user" &&
        <div className="flex-grow">
          <NavLink
            to={bm_menu[0].url}
            className={`fixed bottom-6 flex gap-1 items-center no-underline font-normal text-slate-500 hover:text-blue-700 
                      leading-none border rounded p-2 bg-slate-100`}
          >
            &larr; <span>{bm_menu[0].title}</span>
          </NavLink>
        </div>
        }
    </div>
  )
}
