import React from 'react';
import { Link } from 'react-router-dom';

const OnboardingComplete = () => {
  return (
    <div className="py-3 px-4 mt-3 rounded-lg bg-slate-100/75 text-center">
      <div className="max-w-xl mx-auto">
        <h2 className="font-semibold text-2xl mb-6">Setup Complete!</h2>
        
        <div className="bg-white rounded-lg p-6 mb-8">
          <p className="text-gray-600 mb-4">
            Your firm has been successfully set up. You can now access your Business Manager dashboard.
          </p>

          <Link 
            to="/app/bm/dashboard"
          >
            Go to Business Manager Dashboard &rarr;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OnboardingComplete;
