import React, { Component } from 'react'
import clientsActions from "../../../redux/clients/actions"
import userActions from "../../../redux/user/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { digitСonversion } from "../../../helpers/utils";

const {
  clientsGetForUser,
} = clientsActions;

const {
  userSwitchClient,
} = userActions;

class BMDashboard extends Component {
  componentDidMount = () => {
    this.props.clientsGetForUser({with_bm_dashboard_data: true});
  }

  render() {
    const {
      user_clients,
      user_switch_client_working,
      user_clients_working,
      userData,
    } = this.props;

    return (
      <div>
        <div className="flex items-center justify-between gap-4 mb-8">
          <h1 className="text-xl font-semibold">Business Manager Dashboard</h1>
          {userData?.company?.logo &&
          <img
            src={userData?.company?.logo}
            alt="Company Logo"
            className="h-6"
          />
          }
        </div>
        {user_clients_working ?
        <Loading />
        :
        <div>
          {user_clients.map(item => (
            <div key={`group-${item.id}`} className="">
              <h2 className="uppercase font-semibold text-lg bg-slate-800 text-white px-4 py-1 rounded inline-block mb-1">{item.name}</h2>
              {item?.clients?.length > 0 ? item.clients.map(c => (
                <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4 mt-3" key={`client-${c.slug}`}>
                  <h3
                    className="font-semibold link"
                    role="button"
                    onClick={() => this.props.userSwitchClient({client_id: c.id})}
                    disabled={user_switch_client_working}
                  >
                    {c.name}
                  </h3>
                  <div className="flex gap-4 mt-4">
                    <div className="border rounded p-2 text-right min-w-32 bg-white" key={`c-${c.id}-${item.id}`}>
                      <h3>Overdue</h3>
                      <span>{c?.bm_dashboard_data?.overdue ? c?.bm_dashboard_data?.overdue : 0}</span>
                    </div>
                    <div className="border rounded p-2 text-right min-w-32 bg-white" key={`c-${c.id}-${item.id}`}>
                      <h3>Unpaid</h3>
                      <span>{c?.bm_dashboard_data?.unpaid ? c?.bm_dashboard_data?.unpaid : 0}</span>
                    </div>
                    <div className="border rounded p-2 text-right min-w-32 bg-white" key={`c-${c.id}-${item.id}`}>
                      <h3>Unpaid Amount</h3>
                        <span>{c?.bm_dashboard_data?.unpaid_sum
                          ? digitСonversion(c?.bm_dashboard_data?.unpaid_sum, "currency")
                          : digitСonversion(0, "currency")
                        }</span>
                    </div>
                    <div className="border rounded p-2 text-right min-w-32 bg-white" key={`c-${c.id}-${item.id}`}>
                      <h3>Balance</h3>
                        <span>{c?.bm_dashboard_data?.balance
                          ? digitСonversion(c?.bm_dashboard_data?.balance, "currency")
                          : "N/A"
                        }</span>
                    </div>
                  </div>
                </div>
              ))
              :
              <p className="text-slate-500 mt-2">This group currently has no clients.</p>
              }
            </div>
          ))}
        </div>
        }
      </div>
    )
  }
}

const Loading = () => (
  <div>
    {Array.from(Array(5)).map((item,idx) => (
      <div className="mt-8">
        <div className="bg-slate-300 w-24 h-5 bg-slate-300 rounded mb-2" />
        <div key={`skeleton-${idx}`} className="p-3 rounded flex-1 bg-slate-100/75 animate-pulse">
          <div className="bg-slate-300 w-24 h-5 bg-slate-300 rounded" />
          <div className="flex gap-8">
            {Array.from(Array(5)).map((item,idx) => (
            <div key={`skeleton-task-${idx}`} className="flex flex-col items-end gap-2 mt-2">
              <div className="w-32 h-12 bg-slate-300 rounded" />
            </div>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      user_switch_client_working: state.User.get("user_switch_client_working"),
      user_clients: state.Clients.get("user_clients"),
      user_clients_working: state.Clients.get("user_clients_working"),
    }),
    {
      clientsGetForUser,
      userSwitchClient,
    }
  )(BMDashboard)
);
