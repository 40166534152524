import { commonAction } from "../../helpers/redux/utils";

const userActions = {
	USER_GET_DATA: 'USER_GET_DATA',
	USER_GET_DATA_SUCCESS: 'USER_GET_DATA_SUCCESS',
	USER_GET_DATA_ERROR: 'USER_GET_DATA_ERROR',

	USER_UPDATE_DATA: 'USER_UPDATE_DATA',
	USER_PASSWORD_RESET: 'USER_PASSWORD_RESET',

  USER_UPDATE_SETTINGS: 'USER_UPDATE_SETTINGS',

  USER_SWITCH_CLIENT: 'USER_SWITCH_CLIENT',
  USER_CLEAR_DATA: 'USER_CLEAR_DATA',

  USER_UPDATE_COMPANY: 'USER_UPDATE_COMPANY',

  userUpdateCompany: commonAction("USER_UPDATE_COMPANY"),

  clearUserData: (data) => ({
    type: userActions.USER_CLEAR_DATA,
    data
  }),

  userSwitchClient: (data) => ({
    type: userActions.USER_SWITCH_CLIENT,
    data
  }),

  userUpdateSettings: (data) => ({
    type: userActions.USER_UPDATE_SETTINGS,
    data
  }),

	userPasswordReset: (data) => ({
		type: userActions.USER_PASSWORD_RESET,
    data
	}),

	userUpdateData: commonAction("USER_UPDATE_DATA"),

	userGetData: () => ({
		type: userActions.USER_GET_DATA,
	}),
	userGetDataSuccess: data => ({
		type: userActions.USER_GET_DATA_SUCCESS,
		data
	}),
	userGetDataError: () => ({
		type: userActions.USER_GET_DATA_ERROR,
	}),

}

export default userActions;
